import React from 'react'

import { Link } from 'gatsby'

import { FaFacebook, FaHandsHelping, FaInstagram } from 'react-icons/fa'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import MyPage from '../components/my_page'

const IndexPage = () => {
  const pageTitle = 'Pradžia'
  const pageContent = (
    <div className="jumbotron">
      <p>
        Malonu, kad užsukote! <em>MŪSŲ PĖDUTĖS</em> – privatus darželis
        Vilniuje, kuris nori Jums prisistatyti!
      </p>
      <p>
        Privatus darželis <em>MŪSŲ PĖDUTĖS</em> yra įsikūręs Antežeriuose (šalia
        Zujūnų), labai patogioje vietoje, netoli Vilniaus miesto vakarinio
        aplinkkelio, ir yra ranka pasiekiamas Pilaitėje, Pašilaičiuose,
        Perkūnkiemyje, Justiniškėse, Viršuliškėse, Tarandėje, Avižieniuose
        gyvenančių tėvelių. Vos keletas minučių ir Jus pasitiks:
      </p>
      <ul>
        <li>
          <b>GAMTA!</b> Darželis yra gamtos apsuptyje, labai ramioje vietoje
          (netoli mielo ežeriuko). Kasdien mus džiugina nuosavas,{' '}
          <Link to="/galerija">didžiulis ir žalias kiemas.</Link>
        </li>
        <li>
          <b>Jaukios, šiltos grupių erdvės.</b>{' '}
          <Link to="/galerija">Grupėse</Link> šviesu ir saulėta. O jose vaikus
          supa gamtinės priemonės bei natūralūs, autentiški žaislai pastelinės
          spalvos.
        </li>
        <li>
          <b>Kokybiškas vaiko ugdymas.</b> Vaikai ugdomi pagal gamtiškumu,
          etnokultūra ir psichologinėmis vaiko asmenybės raidos teorijomis
          paremtą ugdymo <Link to="/praktine_informacija">programą</Link>, dirba
          ikimokyklinio ugdymo specialistės –{' '}
          <Link to="/specialistai">auklėtojos.</Link>
        </li>
        <li>
          <b>Papildomas ugdymas.</b> Sporto trenerio, etnografės bei anglų
          kalbos mokytojos vedami <Link to="/galerija">būreliai.</Link>
        </li>
        <li>
          <b>
            Šilti tėvų atsiliepimai apie{' '}
            <Link to="/tevu_atsiliepimai">darželį.</Link>
          </b>
        </li>
        <li>
          <b>
            Taikoma 100 Eur <Link to="/kaina">kompensacija.</Link>
          </b>
        </li>
      </ul>
      <p>
        Privatus darželis <em>MŪSŲ PĖDUTĖS</em> priima vaikus nuo 1 metų!
      </p>
      Darželis yra nominuotas tapti "Gazele". Tai apdovanojimas nedidelėms
      įstaigoms už nepertraukiamą lanksčią, skaidrią, patikimą veiklą, kurioje
      įstaiga sąžiningai kuria ir plėtoja savo idėjas.
      <p>
        Sekite mus{' '}
        <a href="https://www.facebook.com/musu.pedutes/">
          <FaFacebook size={20} />
          Facebook
        </a>{' '}
        ir{' '}
        <a href="https://www.instagram.com/musu.pedutes/">
          <FaInstagram size={20} />
          Instagram
        </a>{' '}
        pirmieji sužinokite apie akcijas, nuolaidas ir renginius.
      </p>
    </div>
  )

  return (
    <MyPage pageType="simple" pageTitle={pageTitle} pageContent={pageContent} />
  )
}

export default IndexPage
